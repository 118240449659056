import store from "@/core/stores";
import Verticals from "@/core/enums/verticals.enum";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export const marketing_v3_flag = () => {
    const state = store.getState();
    const isMarketingVertical =
        state.selectedVertical.vertical === Verticals.Marketing;
    const isMarketingV3Enabled = true;
    return isMarketingVertical && isMarketingV3Enabled;
};

export const marketing_v4_flag = () => {
    const isMarketingV4Enabled = useFeatureIsOn("marketing_v4_flag");
    return !isMarketingV4Enabled && marketing_v3_flag();
};
