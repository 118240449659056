import { getAge } from "@/utils/utilFunctions";
import { Mixpanel, captureEvent } from "../Mixpanel";
import {
    prefix_to_object,
    free_trial_props,
    getSubscriptionDetails,
    getActiveVertical,
} from "../utils";
import i18n from "@/i18n";
import store from "@/core/stores";
import { getUser } from "@/contexts/UserContext";
import { CompanyDetails, CurrUserDetails } from "@/utils/currUserType";
import { Lookup } from "@/models/payload/PlacementFormPayload";

interface Props {
    email: string;
    company: CompanyDetails;
    name: string;
}

export class LoginLogOutSignUpTracking {
    state = store.getState();
    vertical: Lookup | null;
    currUser: CurrUserDetails;
    props: Props;
    constructor(props: Props) {
        this.vertical = this.state.selectedVertical.vertical;
        this.currUser = getUser();
        this.props = props;
    }

    login_viewed = () => {
        captureEvent("Login Page Viewed");
    };

    login_complete = (user: CurrUserDetails, method: string, view: string) => {
        let free_trial_data = {};
        Mixpanel.identify(user.id);
        if (user.company) {
            free_trial_data = free_trial_props(user);
            Mixpanel.register({
                language: i18n.language,
                verified: user.verified,
            });
            Mixpanel.people.set({
                name: user.name,
                email: user.email,
                phone: user.phone,
                role: user.role,
                position: user.position,
                verified: user.verified,
                type_of_user: user.views,
                company: user.company.name,
                company_website: user.company.website,
                company_size: user.company.size,
                company_email_domain: user.company.email_domain,
                company_industry: user.company.industry,
                company_location: user.company.hq_location,
            });
        } else {
            Mixpanel.register({
                language: i18n.language,
            });
            Mixpanel.people.set({
                name: user.name,
                email: user.email,
                age: getAge(user.date_of_birth),
                phone: user.phone,
                gender: user.gender || "not-set",
                city: user.city?.name_en,
                job_type: user.job_type || "not-set",
                type_of_user: user.views,
                major: user.major?.name_en || "not-set",
                has_bachelors: user.has_bachelors,
                university: user.university?.name_en || "not-set",
                months_of_experience: user.months_of_experience || "not-set",
                salary_expectations:
                    user.salary_expectations?.name_en || "not-set",
                verified: true,
            });
        }
        captureEvent("Login Completed", {
            ...free_trial_data,
            login_method: method,
            login_view: view,
        });
    };
    employee_added_from_form = () => {
        captureEvent("Employee Added Form Submitted", {
            email: this.props.email,
            ...prefix_to_object(this.props.company, "company_"),
            name: this.props.name,
        });
    };
    logout_complete = () => {
        captureEvent("Logout Completed", {
            ...getSubscriptionDetails(this.currUser),
            ...getActiveVertical(this.vertical),
        });
    };
    otp_sent = (phone_or_email: string, resend_count: number) => {
        captureEvent("OTP Sent", {
            otp_sent_to: phone_or_email,
            resend_count: resend_count,
        });
    };
}
