import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, {
    AlertColor,
    AlertPropsVariantOverrides,
} from "@mui/material/Alert";
import { OverridableStringUnion } from "@material-ui/types";
import "./snackbar.scss";
import RTL from "@/RTL";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import AlertTitle from "@mui/material/AlertTitle";

type Props = {
    showing: boolean;
    handleClose: (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => void;
    type: AlertColor;
    variant: OverridableStringUnion<
        "standard" | "filled" | "outlined",
        AlertPropsVariantOverrides
    >;
    message: string;
    hideDuration: number | undefined;
    vertical: "top" | "bottom";
    relativeHorizontal: "end" | "start" | "center";
    showIcon: boolean;
    withXButton?: boolean;
    actions?: React.ReactNode;
    children?: React.ReactNode;
};

function ScalersSnackbar(props: Props) {
    const {
        showing,
        handleClose,
        type,
        variant,
        message,
        hideDuration,
        vertical,
        relativeHorizontal,
        showIcon,
        withXButton = false,
        actions,
        children,
    } = props;
    // vertical aligns one to one
    // horizontal mapping:
    // start & end: depending on locale
    // center is center
    const horizontal: "left" | "right" | "center" =
        relativeHorizontal === "start"
            ? "left"
            : relativeHorizontal === "end"
              ? "right"
              : "center";
    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={showing}
            autoHideDuration={hideDuration || 8000}
            onClose={handleClose}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Alert
                data-testid={`snackbar-${type}`}
                classes={{
                    icon: !showIcon ? "hide-icon" : "",
                }}
                onClose={withXButton ? handleClose : undefined}
                severity={type}
                variant={variant}
                sx={{ width: "fit-content" }}
                iconMapping={{
                    success: <CheckCircleOutlineOutlined fontSize="inherit" />,
                }}
            >
                <AlertTitle>{message}</AlertTitle>
                {children && <div className="content">{children}</div>}
                {actions && <div className="actions">{actions}</div>}
            </Alert>
        </Snackbar>
    );
}

export default RTL(ScalersSnackbar);
